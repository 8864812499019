import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// get Blood test Dropdown
export const GET_BLOODTEST_DROPDOWN_REQUEST = "GET_BLOODTEST_DROPDOWN_REQUEST";
export const GET_BLOODTEST_DROPDOWN_SUCCESS = "GET_BLOODTEST_DROPDOWN_SUCCESS";
export const GET_BLOODTEST_DROPDOWN_FAILURE = "GET_BLOODTEST_DROPDOWN_FAILURE";

// get vaccination dropdown
export const GET_VACCINATION_DROPDOWN_REQUEST = "GET_VACCINATION_DROPDOWN_REQUEST";
export const GET_VACCINATION_DROPDOWN_SUCCESS = "GET_VACCINATION_DROPDOWN_SUCCESS";
export const GET_VACCINATION_DROPDOWN_FAILURE = "GET_VACCINATION_DROPDOWN_FAILURE";

// get homeCare dropdown
export const GET_HOMECARE_DROPDOWN_REQUEST = "GET_HOMECARE_DROPDOWN_REQUEST";
export const GET_HOMECARE_DROPDOWN_SUCCESS = "GET_HOMECARE_DROPDOWN_SUCCESS";
export const GET_HOMECARE_DROPDOWN_FAILURE = "GET_HOMECARE_DROPDOWN_FAILURE";

// bloodTEst request
export const CREATE_BLOODTEST_REQUEST = "CREATE_BLOODTEST_REQUEST";
export const CREATE_BLOODTEST_SUCCESS = "CREATE_BLOODTEST_SUCCESS";
export const CREATE_BLOODTEST_FAILURE = "CREATE_BLOODTEST_FAILURE";

// vaccination request
export const CREATE_VACCINATION_REQUEST = "CREATE_VACCINATION_REQUEST";
export const CREATE_VACCINATION_SUCCESS = "CREATE_VACCINATION_SUCCESS";
export const CREATE_VACCINATION_FAILURE = "CREATE_VACCINATION_FAILURE";

// homecare request
export const CREATE_HOMECARE_REQUEST = "CREATE_HOMECARE_REQUEST";
export const CREATE_HOMECARE_SUCCESS = "CREATE_HOMECARE_SUCCESS";
export const CREATE_HOMECARE_FAILURE = "CREATE_HOMECARE_FAILURE";

// get HomeCAre Assign
export const GET_HOMECARE_ASSIGN_REQUEST = "GET_HOMECARE_ASSIGN_REQUEST";
export const GET_HOMECARE_ASSIGN_SUCCESS = "GET_HOMECARE_ASSIGN_SUCCESS";
export const GET_HOMECARE_ASSIGN_FAILURE = "GET_HOMECARE_ASSIGN_FAILURE";

// cancel homecare assing
export const CANCEL_HOMECARE_ASSIGN_REQUEST = "CANCEL_HOMECARE_ASSIGN_REQUEST";
export const CANCEL_HOMECARE_ASSIGN_SUCCESS = "CANCEL_HOMECARE_ASSIGN_SUCCESS";
export const CANCEL_HOMECARE_ASSIGN_FAILURE = "CANCEL_HOMECARE_ASSIGN_FAILURE";

// reschedule homecare request
export const RESCHEDULE_HOMECARE_REQUEST = "RESCHEDULE_HOMECARE_REQUEST";
export const RESCHEDULE_HOMECARE_SUCCESS = "RESCHEDULE_HOMECARE_SUCCESS";
export const RESCHEDULE_HOMECARE_FAILURE = "RESCHEDULE_HOMECARE_FAILURE";

// get All Homecare assigns
export const GET_ALL_HOMECARE_REQUEST = "GET_ALL_HOMECARE_REQUEST";
export const GET_ALL_HOMECARE_SUCCESS = "GET_ALL_HOMECARE_SUCCESS";
export const GET_ALL_HOMECARE_FAILURE = "GET_ALL_HOMECARE_FAILURE";

// get All Vaccination assigns
export const GET_ALL_VACCINATION_REQUEST = "GET_ALL_VACCINATION_REQUEST";
export const GET_ALL_VACCINATION_SUCCESS = "GET_ALL_VACCINATION_SUCCESS";
export const GET_ALL_VACCINATION_FAILURE = "GET_ALL_VACCINATION_FAILURE";

// get All Blood Test assigns
export const GET_ALL_BLOODTEST_REQUEST = "GET_ALL_BLOODTEST_REQUEST";
export const GET_ALL_BLOODTEST_SUCCESS = "GET_ALL_BLOODTEST_SUCCESS";
export const GET_ALL_BLOODTEST_FAILURE = "GET_ALL_BLOODTEST_FAILURE";


// homecare service list:
export const GET_HOMECARE_SUB_SERVICE_REQUEST = "GET_HOMECARE_SUB_SERVICE_REQUEST";
export const GET_HOMECARE_SUB_SERVICE_SUCCESS = "GET_HOMECARE_SUB_SERVICE_SUCCESS";
export const GET_HOMECARE_SUB_SERVICE_FAILURE = "GET_HOMECARE_SUB_SERVICE_FAILURE";

// get all home vacc and bloodtest list:
export const GET_HOME_VACC_BLOOD_REQUEST = "GET_HOME_VACC_BLOOD_REQUEST";
export const GET_HOME_VACC_BLOOD_SUCCESS = "GET_HOME_VACC_BLOOD_SUCCESS";
export const GET_HOME_VACC_BLOOD_FAILURE = "GET_HOME_VACC_BLOOD_FAILURE";

export const GET_FEES_CHARGES_REQUEST = "GET_FEES_CHARGES_REQUEST";
export const GET_FEES_CHARGES_SUCCESS = "GET_FEES_CHARGES_SUCCESS";
export const GET_FEES_CHARGES_FAILURE = "GET_FEES_CHARGES_FAILURE";


export const getFeesCharges = () => {
  return async (dispatch) => {
    try {
      dispatch({type: GET_FEES_CHARGES_REQUEST})
      const url = `${BASE_URL}/charges/list/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: GET_FEES_CHARGES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_FEES_CHARGES_FAILURE,
        // payload: response.data,
      });
    }
  }
}

// get All HomeCare assigns
export const getBloodTestDropdown = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_BLOODTEST_DROPDOWN_REQUEST });
      const url = `${BASE_URL}/vet/get/all/vet/blood/test/type/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: GET_BLOODTEST_DROPDOWN_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_BLOODTEST_DROPDOWN_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getHomecareSubService = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_HOMECARE_SUB_SERVICE_REQUEST });
      const url = `${BASE_URL}/user/get/homecare/service/list/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params:data
      });
      dispatch({
        type: GET_HOMECARE_SUB_SERVICE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_HOMECARE_SUB_SERVICE_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getVaccinationDropdown = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_VACCINATION_DROPDOWN_REQUEST });
      const url = `${BASE_URL}/vet/get/all/vet/vaccine/type/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: GET_VACCINATION_DROPDOWN_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_VACCINATION_DROPDOWN_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getHomecareDropdown = (location) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_HOMECARE_DROPDOWN_REQUEST });
      const url = `${BASE_URL}/user/get/home/care/dynamic/dropdown/list/v1`;
      const response = await axios.post(
        url,
        { location },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({
        type: GET_HOMECARE_DROPDOWN_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_HOMECARE_DROPDOWN_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const createBloodTestRequest = (data, navigate, pathname, paymentStatusData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_BLOODTEST_REQUEST });
      const url = `${BASE_URL}/user/create/blood/test/request/v2`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });

      const appointmentId = response?.data?.apointmentId;
      const nUrl =`/home-care/appointment/scheduled/${appointmentId}`

      // const nUrl = pathname.includes("blood-test")
      //   ? `/blood-test/appointment/scheduled/${appointmentId}`
      //   : pathname.includes("vaccination")
      //   ? `/vaccination/appointment/scheduled/${appointmentId}`
      //   : pathname.includes("home-care")
      //   ? `/home-care/appointment/scheduled/${appointmentId}`
      //   : paymentStatusData?.subType === "BloodTest"
      //   ? `/blood-test/appointment/scheduled/${appointmentId}`
      //   : "";
      navigate(nUrl);
      dispatch({ type: CREATE_BLOODTEST_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: CREATE_BLOODTEST_FAILURE, payload: error.message });
    }
  };
};

export const createVaccinationRequest = (data, navigate, pathname, paymentStatusData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_VACCINATION_REQUEST });
      const url = `${BASE_URL}/user/create/vaccination/request/v2`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // console.log(response?.data?.apointmentId);
      const appointmentId = response?.data?.apointmentId;
      const nUrl =`/home-care/appointment/scheduled/${appointmentId}`
      //  pathname.includes("blood-test")
      //   ? `/blood-test/appointment/scheduled/${appointmentId}`
      //   : pathname.includes("vaccination")
      //   ? `/vaccination/appointment/scheduled/${appointmentId}`
      //   : pathname.includes("home-care")
      //   ? `/home-care/appointment/scheduled/${appointmentId}`
      //   : paymentStatusData?.subType === "Vaccination"
      //   ? `/vaccination/appointment/scheduled/${appointmentId}`
      //   : "";
      navigate(nUrl);
      dispatch({ type: CREATE_VACCINATION_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: CREATE_VACCINATION_FAILURE, payload: error.message });
    }
  };
};

export const createHomeCareRequest = (data, navigate, pathname, paymentStatusData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_HOMECARE_REQUEST });
      const url = `${BASE_URL}/user/create/home/care/request/v2`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      const appointmentId = response?.data?.apointmentId;
      const nUrl =`/home-care/appointment/scheduled/${appointmentId}`
      //  pathname.includes("blood-test")
        // ? `/blood-test/appointment/scheduled/${appointmentId}`
        // : pathname.includes("vaccination")
        // ? `/vaccination/appointment/scheduled/${appointmentId}`
        // : pathname.includes("home-care")
        // ? `/home-care/appointment/scheduled/${appointmentId}`
        // : paymentStatusData?.subType === "Homecare"
        // ? `/home-care/appointment/scheduled/${appointmentId}`
        // : "";
      navigate(nUrl);
      dispatch({ type: CREATE_HOMECARE_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: CREATE_HOMECARE_FAILURE, payload: error.message });
    }
  };
};

export const getHomeCareAssignDetails = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_HOMECARE_ASSIGN_REQUEST });
      const url = `${BASE_URL}/user/get/single/home/care/requests/${id}/v2`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: GET_HOMECARE_ASSIGN_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_HOMECARE_ASSIGN_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const cancelHomecareAssign = (id, data, navigate, pathname) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CANCEL_HOMECARE_ASSIGN_REQUEST });
      const url = `${BASE_URL}/user/cancel/appointment/request/${id}/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      toast.success("Appointment cancelled.");

      dispatch({
        type: CANCEL_HOMECARE_ASSIGN_SUCCESS,
      });

      if (response?.data) {
        const cancelId = response?.data?.data?._id;
        const nUrl = pathname.includes("blood-test")
          ? `/blood-test/appointment/scheduled/${cancelId}`
          : pathname.includes("home-care")
          ? `/home-care/appointment/scheduled/${cancelId}`
          : pathname.includes("vaccination")
          ? `/vaccination/appointment/scheduled/${cancelId}`
          : "";
        navigate(nUrl);
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: CANCEL_HOMECARE_ASSIGN_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const rescheduleHomeCareRequest = (id, data, navigate, pathname) => {
  return async (dispatch) => {
    try {
      dispatch({ type: RESCHEDULE_HOMECARE_REQUEST });
      const url = `${BASE_URL}/user/update/home/care/request/${id}/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: RESCHEDULE_HOMECARE_SUCCESS, payload: response.data });
      if (response?.data) {
        const appointmentId = response?.data?.data?._id;
        const url = pathname.includes("blood-test")
          ? `/blood-test/appointment/scheduled/${appointmentId}`
          : pathname.includes("vaccination")
          ? `/vaccination/appointment/scheduled/${appointmentId}`
          : pathname.includes("home-care")
          ? `/home-care/appointment/scheduled/${appointmentId}`
          : "";
        navigate(url, {
          state: { forReschedule: true },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: RESCHEDULE_HOMECARE_FAILURE, payload: error.message });
    }
  };
};

export const getBloodtestHomecareAndVaccineList = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_HOME_VACC_BLOOD_REQUEST });
      const url = `${BASE_URL}/user/get/home/vacc/blood/care/requests/list/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params:data
      });
      dispatch({
        type: GET_HOME_VACC_BLOOD_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_HOME_VACC_BLOOD_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getAllHomecareAssigns = (page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_HOMECARE_REQUEST });
      const url = `${BASE_URL}/user/get/all/my/home/care/requests/list/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: {
          page: page,
        },
      });
      dispatch({
        type: GET_ALL_HOMECARE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_ALL_HOMECARE_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getAllVaccinationAssigns = (page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_VACCINATION_REQUEST });
      const url = `${BASE_URL}/user/get/all/my/vaccination/requests/list/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: {
          page: page,
        },
      });
      dispatch({
        type: GET_ALL_VACCINATION_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_ALL_VACCINATION_FAILURE,
        payload: error.message,
      });
    }
  };
};
export const getAllBloodtestAssigns = (page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_BLOODTEST_REQUEST });
      const url = `${BASE_URL}/user/get/all/my/blood/test/requests/list/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: {
          page: page,
        },
      });
      dispatch({
        type: GET_ALL_BLOODTEST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_ALL_BLOODTEST_FAILURE,
        payload: error.message,
      });
    }
  };
};
