import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const CREATE_PET_REQUEST = "CREATE_PET_REQUEST";
export const CREATE_PET_SUCCESS = "CREATE_PET_SUCCESS";
export const CREATE_PET_FAILURE = "CREATE_PET_FAILURE";

export const GET_MY_PETS_REQUEST = "GET_MY_PETS_REQUEST";
export const GET_MY_PETS_SUCCESS = "GET_MY_PETS_SUCCESS";
export const GET_MY_PETS_FAILURE = "GET_MY_PETS_FAILURE";

// update my pet info
export const UPDATE_MY_PET_REQUEST = "UPDATE_MY_PET_REQUEST";
export const UPDATE_MY_PET_SUCCESS = "UPDATE_MY_PET_SUCCESS";
export const UPDATE_MY_PET_FAILURE = "UPDATE_MY_PET_FAILURE";

// goverment pet
export const CREATE_GOVERMENT_PET_REQUEST = "CREATE_GOVERMENT_PET_REQUEST";
export const CREATE_GOVERMENT_PET_SUCCESS = "CREATE_GOVERMENT_PET_SUCCESS";
export const CREATE_GOVERMENT_PET_FAILURE = "CREATE_GOVERMENT_PET_FAILURE";

// get Goverment pets
export const GET_MY_GOVERMENT_PETS_REQUEST = "GET_MY_GOVERMENT_PETS_REQUEST";
export const GET_MY_GOVERMENT_PETS_SUCCESS = "GET_MY_GOVERMENT_PETS_SUCCESS";
export const GET_MY_GOVERMENT_PETS_FAILURE = "GET_MY_GOVERMENT_PETS_FAILURE";

// get goverment pet Details
export const GET_MY_GOVERMENT_PET_DETAILS_REQUEST = "GET_MY_GOVERMENT_PET_DETAILS_REQUEST";
export const GET_MY_GOVERMENT_PET_DETAILS_SUCCESS = "GET_MY_GOVERMENT_PET_DETAILS_SUCCESS";
export const GET_MY_GOVERMENT_PET_DETAILS_FAILURE = "GET_MY_GOVERMENT_PET_DETAILS_FAILURE";

// delete My goverment Pets
export const DELETE_MY_GOVERMENT_PET_REQUEST = "DELETE_MY_GOVERMENT_PET_REQUEST";
export const DELETE_MY_GOVERMENT_PET_SUCCESS = "DELETE_MY_GOVERMENT_PET_SUCCESS";
export const DELETE_MY_GOVERMENT_PET_FAILURE = "DELETE_MY_GOVERMENT_PET_FAILURE";

// update My Gove pets
export const UPDATE_MY_GOVERMENT_PET_REQUEST = "UPDATE_MY_GOVERMENT_PET_REQUEST";
export const UPDATE_MY_GOVERMENT_PET_SUCCESS = "UPDATE_MY_GOVERMENT_PET_SUCCESS";
export const UPDATE_MY_GOVERMENT_PET_FAILURE = "UPDATE_MY_GOVERMENT_PET_FAILURE";

// get E-healthCards pets
export const GET_MY_EHEALTHCARD_PETS_REQUEST = "GET_MY_EHEALTHCARD_PETS_REQUEST";
export const GET_MY_EHEALTHCARD_PETS_SUCCESS = "GET_MY_EHEALTHCARD_PETS_SUCCESS";
export const GET_MY_EHEALTHCARD_PETS_FAILURE = "GET_MY_EHEALTHCARD_PETS_FAILURE";

// create E-healthcard
export const CREATE_EHEALTHCARD_REQUEST = "CREATE_EHEALTHCARD_REQUEST";
export const CREATE_EHEALTHCARD_SUCCESS = "CREATE_EHEALTHCARD_SUCCESS";
export const CREATE_EHEALTHCARD_FAILURE = "CREATE_EHEALTHCARD_FAILURE";

// delete Pet E-healthcard
export const DELETE_PET_REQUEST = "DELETE_PET_REQUEST";
export const DELETE_PET_SUCCESS = "DELETE_PET_SUCCESS";
export const DELETE_PET_FAILURE = "DELETE_PET_FAILURE";
// get Single pet Request
export const GET_SINGLE_PET_REQUEST = "GET_SINGLE_PET_REQUEST";
export const GET_SINGLE_PET_SUCCESS = "GET_SINGLE_PET_SUCCESS";
export const GET_SINGLE_PET_FAILURE = "GET_SINGLE_PET_FAILURE";

// get Breed dropdown
export const GET_PET_BREED_REQUEST = "GET_PET_BREED_REQUEST";
export const GET_PET_BREED_SUCCESS = "GET_PET_BREED_SUCCESS";
export const GET_PET_BREED_FAILURE = "GET_PET_BREED_FAILURE";

// get Allergies dropdown
export const GET_PET_ALERGIES_REQUEST = "GET_PET_ALERGIES_REQUEST";
export const GET_PET_ALERGIES_SUCCESS = "GET_PET_ALERGIES_SUCCESS";
export const GET_PET_ALERGIES_FAILURE = "GET_PET_ALERGIES_FAILURE";

export const createNewPet = (data, clearForm) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_PET_REQUEST });

      // if Image then
      if (data && data.file) {
        const urlPre = `${BASE_URL}/pet/upload/pet/profile/image/v1`;
        const responsePre = await axios.post(
          urlPre,
          { sFileName: data.file.name, sContentType: data.file.type },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );

        const { sUrl, sPath } = responsePre.data.data;
        data.petImage = sPath;

        axios.put(sUrl, data.file, {
          headers: {
            "Content-Type": data.file.type,
          },
        });
      }

      // if certificate then
      // if (data && data?.vaccineCertificate) {
      //   const file = data?.vaccineCertificate;
      //   const urlPre = `${BASE_URL}/pet/upload/goverment/register/pet/image/v1`;
      //   const responsePre = await axios.post(
      //     urlPre,
      //     { sFileName: file.name, sContentType: file.type },
      //     {
      //       headers: {
      //         Authorization: localStorage.getItem("token"),
      //       },
      //     }
      //   );

      //   const { sUrl, sPath } = responsePre.data.data;
      //   data.rabiesVaccinatedCertificate = sPath;

      //   axios.put(sUrl, file, {
      //     headers: {
      //       "Content-Type": file.type,
      //     },
      //   });
      // }

      const url = `${BASE_URL}/pet/create/info/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      clearForm();
      toast.success("Pet added successfully.");

      dispatch({ type: CREATE_PET_SUCCESS, payload: response?.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: CREATE_PET_FAILURE, payload: error.message });
    }
  };
};

export const createNewEHealthcardPet = (data, setForm, setVaccineForm, setAllergyForm, isClickEdit, clearForm) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_EHEALTHCARD_REQUEST });
      const url = `${BASE_URL}/pet/create/pet/registration/info/web/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });

      toast.success(isClickEdit ? "E-HealthCard updated successfully" : "E-HealthCard created successfully");
      dispatch({ type: CREATE_EHEALTHCARD_SUCCESS, payload: response?.data });
      clearForm();
      setVaccineForm([
        {
          vetOrClinic: "",
          vaccineId: null,
          vaccinationDate: "",
        },
      ]);
      setAllergyForm([null]);
    } catch (error) {
      console.error(error);
      dispatch({ type: CREATE_EHEALTHCARD_FAILURE, payload: error.message });
    }
  };
};

export const getMyPets = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_MY_PETS_REQUEST });
      const url = `${BASE_URL}/pet/get/all/info/list/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // alert(JSON.stringify(response?.data))
      // toast.success("pet Get Successfull");
      dispatch({ type: GET_MY_PETS_SUCCESS, payload: response?.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_MY_PETS_FAILURE, payload: error.message });
    }
  };
};

export const updateMyPetInfo = (id, data, clearForm) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_MY_PET_REQUEST });
      if (data && data.file) {
        const urlPre = `${BASE_URL}/pet/upload/pet/profile/image/v1`;
        const responsePre = await axios.post(
          urlPre,
          { sFileName: data.file.name, sContentType: data.file.type },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );

        const { sUrl, sPath } = responsePre.data.data;
        data.petImage = sPath;

        axios.put(sUrl, data.file, {
          headers: {
            "Content-Type": data.file.type,
          },
        });
      }

      const url = `${BASE_URL}/pet/update/pet/info/${id}/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // alert(JSON.stringify(response?.data))
      toast.success("Pet is updated successfully.");
      clearForm();
      dispatch({ type: UPDATE_MY_PET_SUCCESS, payload: response?.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: UPDATE_MY_PET_FAILURE, payload: error.message });
    }
  };
};

export const getMyEhealthCardPets = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_MY_EHEALTHCARD_PETS_REQUEST });
      const url = `${BASE_URL}/pet/get/all/e/health/card/pet/list/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });

      dispatch({
        type: GET_MY_EHEALTHCARD_PETS_SUCCESS,
        payload: response?.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_MY_EHEALTHCARD_PETS_FAILURE,
        payload: error.message,
      });
    }
  };
};

// for pet and ehealthcard same
export const deleteEhealthPetEhealthCard = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "DELETE_EHEALTHCARD_CLEAR_STATE" });
      dispatch({ type: DELETE_PET_REQUEST });
      const url = `${BASE_URL}/pet/delete/pet/health/card/${id}/v1`;
      const response = await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      toast.success("Pet deleted successfully.");
      dispatch({ type: DELETE_PET_SUCCESS, payload: response?.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: DELETE_PET_FAILURE, payload: error.message });
    }
  };
};

export const getSinglePetRequest = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SINGLE_PET_REQUEST });
      const url = `${BASE_URL}/pet/get/pet/info/${id}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // alert(JSON.stringify(response?.data))
      // toast.success("pet Get Successfull");
      dispatch({ type: GET_SINGLE_PET_SUCCESS, payload: response?.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_SINGLE_PET_FAILURE, payload: error.message });
    }
  };
};

export const getPetBreed = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PET_BREED_REQUEST });
      const url = `${BASE_URL}/pet/get/breed/${id}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // toast.success("pet Created Successfull");
      dispatch({ type: GET_PET_BREED_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_PET_BREED_FAILURE, payload: error.message });
    }
  };
};

export const getPetsAllergiesDropdown = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PET_ALERGIES_REQUEST });
      const url = `${BASE_URL}/pet/get/all/allergies/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      // alert(JSON.stringify(response?.data))
      // toast.success("pet Get Successfull");
      dispatch({ type: GET_PET_ALERGIES_SUCCESS, payload: response?.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_PET_ALERGIES_FAILURE, payload: error.message });
    }
  };
};

export const getMyGovermentPets = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_MY_GOVERMENT_PETS_REQUEST });
      const url = `${BASE_URL}/pet/get/my/goverment/pet/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });

      dispatch({
        type: GET_MY_GOVERMENT_PETS_SUCCESS,
        payload: response?.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_MY_GOVERMENT_PETS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getMyGovermentPetDetails = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_MY_GOVERMENT_PET_DETAILS_REQUEST });
      const url = `${BASE_URL}/pet/get/single/goverment/register/pet/${id}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });

      dispatch({
        type: GET_MY_GOVERMENT_PET_DETAILS_SUCCESS,
        payload: response?.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_MY_GOVERMENT_PET_DETAILS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const createGovermentPetRegistration = (data, clearForm) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_GOVERMENT_PET_REQUEST });

      // if Image then
      if (data && data.file) {
        const urlPre = `${BASE_URL}/pet/upload/goverment/register/pet/image/v1`;
        const responsePre = await axios.post(
          urlPre,
          { sFileName: data.file.name, sContentType: data.file.type },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );

        const { sUrl, sPath } = responsePre.data.data;
        data.petImage = sPath;

        axios.put(sUrl, data.file, {
          headers: {
            "Content-Type": data.file.type,
          },
        });
      }

      // if certificate then
      if (data && data?.vaccineCertificate) {
        const file = data?.vaccineCertificate;
        const urlPre = `${BASE_URL}/pet/upload/goverment/register/pet/image/v1`;
        const responsePre = await axios.post(
          urlPre,
          { sFileName: file.name, sContentType: file.type },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );

        const { sUrl, sPath } = responsePre.data.data;
        data.rabiesVaccinatedCertificate = sPath;

        axios.put(sUrl, file, {
          headers: {
            "Content-Type": file.type,
          },
        });
      }
      const url = `${BASE_URL}/pet/register/goverment/pet/web/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      clearForm();
      toast.success("Goverment pet created successfully.");
      dispatch({ type: CREATE_GOVERMENT_PET_SUCCESS, payload: response?.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: CREATE_GOVERMENT_PET_FAILURE, payload: error.message });
    }
  };
};

export const deleteMyGovermentPets = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_MY_GOVERMENT_PET_REQUEST });
      const url = `${BASE_URL}/pet/delete/goverment/pet/${id}/v1`;
      const response = await axios.put(url, {}, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });

      toast.success("Goverment pet deleted successfully.");
      dispatch({
        type: DELETE_MY_GOVERMENT_PET_SUCCESS,
        // payload: response?.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: DELETE_MY_GOVERMENT_PET_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const updateMyGovermentPets = (id, data, clearForm) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_MY_GOVERMENT_PET_REQUEST });
      // if Image then
      if (data && data.file) {
        const urlPre = `${BASE_URL}/pet/upload/goverment/register/pet/image/v1`;
        const responsePre = await axios.post(
          urlPre,
          { sFileName: data.file.name, sContentType: data.file.type },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );

        const { sUrl, sPath } = responsePre.data.data;
        data.petImage = sPath;

        axios.put(sUrl, data.file, {
          headers: {
            "Content-Type": data.file.type,
          },
        });
      }

      // if certificate then
      if (data && data?.vaccineCertificate) {
        const file = data?.vaccineCertificate;
        const urlPre = `${BASE_URL}/pet/upload/goverment/register/pet/image/v1`;
        const responsePre = await axios.post(
          urlPre,
          { sFileName: file.name, sContentType: file.type },
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );

        const { sUrl, sPath } = responsePre.data.data;
        data.rabiesVaccinatedCertificate = sPath;

        axios.put(sUrl, file, {
          headers: {
            "Content-Type": file.type,
          },
        });
      }
      const url = `${BASE_URL}/pet/update/goverment/pet/${id}/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });

      toast.success("Goverment pet updated successfully.");
      clearForm();
      dispatch({
        type: UPDATE_MY_GOVERMENT_PET_SUCCESS,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: UPDATE_MY_GOVERMENT_PET_FAILURE,
        payload: error.message,
      });
    }
  };
};
