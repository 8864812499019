import React from "react";
import { Col, Row } from "react-bootstrap";

import dog from "../../assets/svg/pet_service/dog.svg";
import bird from "../../assets/svg/pet_service/bird.svg";
import chameleon from "../../assets/svg/pet_service/chameleon.svg";
import cat from "../../assets/svg/pet_service/cat.svg";
import turtle from "../../assets/svg/pet_service/turtle.svg";
import mammals from "../../assets/svg/pet_service/mammals.svg";
import cow from "../../assets/svg/pet_service/cow.svg";
import star1 from "../../assets/svg/star1.svg";
const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
const DoctorInfoCard = ({ vet, consultationInfo, treatmentFees }) => {
  return (
    <div className="box_container doctor_info_card">
      <Row>
        <Col lg={6} md={12} sm={12} xs={12} className="doctor_info_card_photo">
          <img
            src={
              vet?.personalInfo?.image
                ? imageBaseUrl + vet?.personalInfo?.image
                : consultationInfo?.personalInfo?.image
                ? imageBaseUrl + consultationInfo?.personalInfo?.image
                : "https://picsum.photos/200"
            }
            alt="doctor_image"
          />
        </Col>
        <Col lg={6} md={12} sm={12} xs={12}>
          <div className="doctor_info_card_name">
            <h6>
              Dr.
              {vet?.personalInfo?.name || consultationInfo?.personalInfo?.name}
            </h6>
            <div>
              <img src={star1} alt="star_icon" />
              <span>
                {vet?.vetInfo?.rating || consultationInfo?.vetId?.rating}
              </span>
            </div>
          </div>
          <div className="doctor_info_card_profession">
            <div>
              <span>Experience</span>
              <p>
                {vet?.vet_professional_info?.experianceDetails?.reduce(
                  (totalExperience, e) => {
                    return totalExperience + e?.totalExperience;
                  },
                  0
                ) ||
                  consultationInfo?.vet_professional_info?.experianceDetails?.reduce(
                    (totalExperience, e) => {
                      return totalExperience + e?.totalExperience;
                    },
                    0
                  )||0}{" "}
                years
              </p>
            </div>
            <div>
              <span>Happy Pets</span>
              <p>
                {vet?.vetInfo?.happyPets || consultationInfo?.vetId?.happyPets||"0"}
              </p>
            </div>
            <div>
              <span>Fees</span>
              <p>
                &#8377;{" "}
                {treatmentFees ?? vet?.consultation_prefer?.consultationFees ?? consultationInfo?.consultation_prefer?.consultationFees}
              </p>
            </div>
          </div>
          <Row className="doctor_info_card_pet_category">
            <Col md={12}>
              <p>Pet’s Category</p>
            </Col>
            {(Array.isArray(vet?.consultation_prefer?.selectPets)
              ? vet.consultation_prefer.selectPets
              : []
            )
              .concat(
                Array.isArray(consultationInfo?.consultation_prefer?.selectPets)
                  ? consultationInfo.consultation_prefer.selectPets
                  : []
              )
              .map((p) => (
                <Col key={p?.id} lg={3} md={3} sm={3} xs={3}>
                  <div>
                    <div>
                      <img src={imageBaseUrl + p?.petImage} alt="dog_icon" />
                    </div>
                    <p>{p?.name}</p>
                  </div>
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default DoctorInfoCard;
