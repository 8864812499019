import React from "react";

const AboutVeterian = ({ vet, consultationInfo }) => {
  // console.log(consultationInfo)
  return (
    <div className="box_container about_veterian_box">
      <h6>About Veterinarian</h6>

      {/* <span>Description</span> */}
      <div
        className="form-control"
        style={{
          // width: "1000px",
          height: "auto",
          overflow: "visible",
        }}
        readOnly
        dangerouslySetInnerHTML={{
          __html: vet?.personalInfo?.aboutVet,
        }}
      ></div>
      <br />

      <span>Professional Experience</span>
      <ul>
        {vet?.vet_professional_info?.experianceDetails?.map((e) => {
          return (
            <li>
              {e?.from + " to " + e?.to} - {e?.organisation}
            </li>
          );
        }) ||
          consultationInfo?.vet_professional_info?.experianceDetails?.map(
            (e) => {
              return (
                <li>
                  {e?.from + " to " + e?.to} - {e?.organisation}
                </li>
              );
            }
          )}{" "}
        {/* <li>2014 - 2020 Veterinary Surgeon at The Animal Friend's Hospital.</li>
        <li>2014 - 2020 Veterinary Surgeon at The Animal Friend's Hospital.</li> */}
      </ul>
      <span>Qualification</span>
      {/* <p>
        Bachelor in Veterinary Science and Animal Husbandary (BVSc & AH) - Lala
        Lajpat Rai University of Veterinary and Animal Sciences, Hisar, 2014
      </p> */}

      <p>
        {vet?.vet_professional_info?.educationalQualification?.map((e) => {
          return (
            <>
              <div className="m-2">
                <ul className="list-disc pl-4">
                  <li><strong>Degree Name:</strong> {e?.degreeType?.name}</li>
                  <li><strong>Veterinary Degree:</strong> {e?.veterinaryDegree?.name}</li>
                  <li><strong>Year of Graduation:</strong> {e?.yearOfGraduation}</li>
                </ul>
              </div>
            </>
          );
        })}
      </p>
    </div>
  );
};

export default AboutVeterian;
