import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_SCHEDULE_VETS_REQUEST = "GET_SCHEDULE_VETS_REQUEST";
export const GET_SCHEDULE_VETS_SUCCESS = "GET_SCHEDULE_VETS_SUCCESS";
export const GET_SCHEDULE_VETS_FAILURE = "GET_SCHEDULE_VETS_FAILURE";

export const CREATE_CONSULTATION_ASSIGN_REQUEST = "CREATE_CONSULTATION_ASSIGN_REQUEST";
export const CREATE_CONSULTATION_ASSIGN_SUCCESS = "CREATE_CONSULTATION_ASSIGN_SUCCESS";
export const CREATE_CONSULTATION_ASSIGN_FAILURE = "CREATE_CONSULTATION_ASSIGN_FAILURE";

// get Consultation Details
export const GET_CONSULTATION_ASSIGN_REQUEST = "GET_CONSULTATION_ASSIGN_REQUEST";
export const GET_CONSULTATION_ASSIGN_SUCCESS = "GET_CONSULTATION_ASSIGN_SUCCESS";
export const GET_CONSULTATION_ASSIGN_FAILURE = "GET_CONSULTATION_ASSIGN_FAILURE";

export const GET_FILTER_SCHEDULE_VETS_REQUEST = "GET_FILTER_SCHEDULE_VETS_REQUEST";
export const GET_FILTER_SCHEDULE_VETS_SUCCESS = "GET_FILTER_SCHEDULE_VETS_SUCCESS";
export const GET_FILTER_SCHEDULE_VETS_FAILURE = "GET_FILTER_SCHEDULE_VETS_FAILURE";

// cancel SChedule
export const CANCEL_SCHEDULE_VETS_REQUEST = "CANCEL_SCHEDULE_VETS_REQUEST";
export const CANCEL_SCHEDULE_VETS_SUCCESS = "CANCEL_SCHEDULE_VETS_SUCCESS";
export const CANCEL_SCHEDULE_VETS_FAILURE = "CANCEL_SCHEDULE_VETS_FAILURE";

// reschedule vet request
export const RESCHEDULE_VETS_REQUEST = "RESCHEDULE_VETS_REQUEST";
export const RESCHEDULE_VETS_SUCCESS = "RESCHEDULE_VETS_SUCCESS";
export const RESCHEDULE_VETS_FAILURE = "RESCHEDULE_VETS_FAILURE";

// get Consultation List
export const GET_CONSULTATION_ASSIGN_LIST_REQUEST = "GET_CONSULTATION_ASSIGN_LIST_REQUEST";
export const GET_CONSULTATION_ASSIGN_LIST_SUCCESS = "GET_CONSULTATION_ASSIGN_LIST_SUCCESS";
export const GET_CONSULTATION_ASSIGN_LIST_FAILURE = "GET_CONSULTATION_ASSIGN_LIST_FAILURE";

// cancel reason dropdown
export const CANCEL_REASON_DROPDOWN_REQUEST = "CANCEL_REASON_DROPDOWN_REQUEST";
export const CANCEL_REASON_DROPDOWN_SUCCESS = "CANCEL_REASON_DROPDOWN_SUCCESS";
export const CANCEL_REASON_DROPDOWN_FAILURE = "CANCEL_REASON_DROPDOWN_FAILURE";

// Instant Vidio Call request:
export const SEND_INSTANT_VIDIO_CALL_REQUEST_TO_VET_REQUEST = "SEND_INSTANT_VIDIO_CALL_REQUEST_TO_VET_REQUEST";
export const SEND_INSTANT_VIDIO_CALL_REQUEST_TO_VET_SUCCESS = "SEND_INSTANT_VIDIO_CALL_REQUEST_TO_VET_SUCCESS";
export const SEND_INSTANT_VIDIO_CALL_REQUEST_TO_VET_FAILURE = "SEND_INSTANT_VIDIO_CALL_REQUEST_TO_VET_FAILURE";

// Get instant consultation fees:
export const GET_INSTANT_CONSULTATION_FEES_REQUEST = "GET_INSTANT_CONSULTATION_FEES_REQUEST";
export const GET_INSTANT_CONSULTATION_FEES_SUCCESS = "GET_INSTANT_CONSULTATION_FEES_SUCCESS";
export const GET_INSTANT_CONSULTATION_FEES_FAILURE = "GET_INSTANT_CONSULTATION_FEES_FAILURE";

//Upload chat Consultation image:
export const GET_IMAGE_PATH_IN_CHAT_REQUEST = "GET_IMAGE_PATH_IN_CHAT_REQUEST";
export const GET_IMAGE_PATH_IN_CHAT_SUCCESS = "GET_IMAGE_PATH_IN_CHAT_SUCCESS";
export const GET_IMAGE_PATH_IN_CHAT_FAILURE = "GET_IMAGE_PATH_IN_CHAT_FAILURE";

// Homecare vet list
export const GET_HOMECARE_VET_LIST_REQUEST = "GET_HOMECARE_VET_LIST_REQUEST";
export const GET_HOMECARE_VET_LIST_SUCCESS = "GET_HOMECARE_VET_LIST_SUCCESS";
export const GET_HOMECARE_VET_LIST_FAILURE = "GET_HOMECARE_VET_LIST_FAILURE";

export const getScheduleVetsList = (data, page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SCHEDULE_VETS_REQUEST });
      const url = `${BASE_URL}/user/get/consultation/vet/list/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: {
          page: page,
        },
      });

      dispatch({ type: GET_SCHEDULE_VETS_SUCCESS, payload: response.data });

    } catch (error) {
      console.error(error);
      dispatch({ type: GET_SCHEDULE_VETS_FAILURE, payload: error.message });
    }
  };
};

export const getConsultationAssignList = (page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CONSULTATION_ASSIGN_LIST_REQUEST });
      const url = `${BASE_URL}/user/get/my/asign/vet/schedule/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: {
          page: page,
        },
      });
      dispatch({
        type: GET_CONSULTATION_ASSIGN_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_CONSULTATION_ASSIGN_LIST_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getFilterScheduleVetsList = (data, page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_FILTER_SCHEDULE_VETS_REQUEST });
      const url = `${BASE_URL}/user/filter/consultation/vet/list/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: {
          page: page,
        },
      });
      dispatch({
        type: GET_FILTER_SCHEDULE_VETS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_FILTER_SCHEDULE_VETS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const createScheduleAssign = (data, navigate, pathname, formData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_CONSULTATION_ASSIGN_REQUEST });
      const url = `${BASE_URL}/user/create/asign/vet/schedule/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: CREATE_CONSULTATION_ASSIGN_SUCCESS,
        payload: response.data,
      });
      if (response?.data) {
        const nUrl = `/consultation/schedule-consultation/doctor-details/${response?.data?.newSchedule?._id}/appointment/scheduled`;
        navigate(nUrl, {
          state: { formData },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: CREATE_CONSULTATION_ASSIGN_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getScheduleAssignDetails = (id,location) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CONSULTATION_ASSIGN_REQUEST });
      const url = `${BASE_URL}/user/get/asign/vet/schedule/${id}/v1`;
      const response = await axios.get(
        url,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
          params: { lat: location?.lat, lng: location?.lng }
        }
      );
      dispatch({
        type: GET_CONSULTATION_ASSIGN_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_CONSULTATION_ASSIGN_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const cancelScheduleAssign = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CANCEL_SCHEDULE_VETS_REQUEST });
      const url = `${BASE_URL}/user/delete/asign/vet/schedule/${id}/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: CANCEL_SCHEDULE_VETS_SUCCESS,
        payload: response.data,
      });
      toast.success("Appointment cancelled.");
    } catch (error) {
      console.error(error);
      dispatch({
        type: CANCEL_SCHEDULE_VETS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const rescheduleScheduleAssign = (id, data, navigate, pathname, formData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: RESCHEDULE_VETS_REQUEST });
      const url = `${BASE_URL}/user/update/asign/vet/schedule/${id}/v1`;
      const response = await axios.put(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      toast.success("Reschedule successfully");
      if (response?.data) {
        navigate(
          `/consultation/schedule-consultation/doctor-details/${response?.data?.data?._id}/appointment/scheduled`,
          {
            state: {
              forReschedule: true,
              formData,
            },
          }
        );
      }
      dispatch({
        type: RESCHEDULE_VETS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: RESCHEDULE_VETS_FAILURE,
        payload: error.message,
      });
    }
  };
};

// cancellation dropdown
export const cancellationDropdown = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: CANCEL_REASON_DROPDOWN_REQUEST });
      const url = `${BASE_URL}/admin/get/all/cancel/reason/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: CANCEL_REASON_DROPDOWN_SUCCESS,
        payload: response.data,
      });
      // toast.success(" Get");
    } catch (error) {
      console.error(error);
      dispatch({
        type: CANCEL_REASON_DROPDOWN_FAILURE,
        payload: error.message,
      });
    }
  };
};

// Instant request to vet:
export const sendRequestToVet = (formData,navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SEND_INSTANT_VIDIO_CALL_REQUEST_TO_VET_REQUEST });
      const url = `${BASE_URL}/user/send/request/to/available/vets/v2`;
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: SEND_INSTANT_VIDIO_CALL_REQUEST_TO_VET_SUCCESS,
        payload: response.data,
      });
      // localStorage.setItem("instantConsultationData", JSON.stringify(response.data));
      // localStorage.setItem("instantConsultationData", JSON.stringify({isApiCall:true}));
      const data=response.data;
      const newData = {
        data: {
          vetId: null, 
        },
        chatId: data?.chatId,
        channelName: data?.channelName,
        consultId: data?.consultId,
      };
      navigate("/consultation/instant-consultation/doctor-details/connect", {
                state: {instantConsultationData:newData},
      });      
    } catch (error) {
      console.error(error);
      dispatch({
        type: SEND_INSTANT_VIDIO_CALL_REQUEST_TO_VET_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getInstantConsultationFees = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_INSTANT_CONSULTATION_FEES_REQUEST });
      const url = `${BASE_URL}/admin/get/instant/consultation/fees/for/user/v1`;

      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_INSTANT_CONSULTATION_FEES_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_INSTANT_CONSULTATION_FEES_FAILURE, payload: error.message });
    }
  };
};

export const getImagePathForChatConsultation = (file, messageInputRef) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_IMAGE_PATH_IN_CHAT_REQUEST });
      const url = `${BASE_URL}/consultation/upload/image/in/chat/v1`;
      const responsePre = await axios.post(
        url,
        { sFileName: file?.name, sContentType: file?.type },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      const { sUrl, sPath } = responsePre.data.data;

      const data = await axios.put(sUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
      });
      messageInputRef.current = sPath;
      dispatch({ type: GET_IMAGE_PATH_IN_CHAT_SUCCESS, payload: sPath });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_IMAGE_PATH_IN_CHAT_FAILURE, payload: error.message });
    }
  };
};



export const getVetListForHomecare = (data,page) => {
  return async (dispatch) => {
    const location = JSON.parse(localStorage.getItem("location") || "{}");
    try {
      dispatch({ type: GET_HOMECARE_VET_LIST_REQUEST });
      const url = `${BASE_URL}/user/get/homecare/vet/list/v1`;
      const response = await axios.post(url,{
        ...data,
        location
      }, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: {
          page: page,
          search: ""
        },
      });
      dispatch({
        type: GET_HOMECARE_VET_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: GET_HOMECARE_VET_LIST_FAILURE,
        payload: error.message,
      });
    }
  };
};